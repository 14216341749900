import React, { useEffect, useState } from 'react';
import aboutbg from "../assets/images/aboutbg.jpg";
import BestSeller from './BestSellers';
import FormContact from './ContactUs';
import ServicesDemo from './ServicesDemo';

const ImageGallery = (props) => {
  

  const [current, setCurrent] = useState(0);

  const nextSlide = () => {
      setCurrent(prev => (prev + 1) % props.images.length);
  };

  const handleDotClick = (index) => {
      setCurrent(index);
  };

  useEffect(() => {
      const interval = setInterval(nextSlide, 3000);
      return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>


      <section>
        
        <div className=" overflow-hidden">
                <div
                    className="flex transition-transform ease-out duration-1000"
                    style={{ transform: `translateX(-${current * 100}%)` }}
                >
                    {props.images.map((slide, i) => (
                        <div className="w-full flex flex-shrink-0 justify-start items-start" key={i}>
                            <div
                                className="w-full lg:h-screen md:h-96 h-96 flex justify-start
                                 items-center 
                                bg-cover bg-center"
                                style={{ backgroundImage: `url(${slide.image})` }}
                            >
                                <div className="text-center bg-black bg-opacity-70 md:p-4 lg:p-8  p-4">
                                    <p className="w-full text-white lg:text-4xl md:text-2xl text-xl font-bold">
                                        <span>
                                            {slide.heading}
                                        </span>
                                    </p>
                                    <p className="w-full">
                                        <span className="md:text-xl text-lg  text-white pl-8">
                                            {slide.text}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* <div className="absolute md:bottom-[180px]  lg:bottom-[90px] bottom-40 left-1/2 
                transform -translate-x-1/2 flex gap-[10px] border border-red-500"> */}
                <div className="absolute md:bottom-[180px]  lg:bottom-[90px] bottom-40 left-1/2 
                transform -translate-x-1/2 flex gap-[10px]">
                    {props.images.map((_, index) => (
                        <div
                            key={index}
                            className={`w-[15px] h-[15px] rounded-full cursor-pointer ${index === current ? 
                                'bg-green-500' : 'bg-gray-300'}`}
                            onClick={() => handleDotClick(index)}
                        />
                    ))}
                </div>
            </div>
                        <div className="relative lg:top-[-100px] md:top-[-40px] -mb-32 md:-mb-0
            lg:flex md:flex-none flex-none justify-around lg:gap-6 bg-regal-blue px-3 md:ml-6  md:mr-6 lg:ml-20 lg:mr-20 lg:pt-20 lg:py-20 lg:px-20  mt-3 py-6 md:py-0">
                        {/* <div className="relative lg:top-[-150px] md:top-[-180px] top-[-180px] 
            lg:flex md:flex-none flex-none justify-around lg:gap-6 bg-regal-blue lg:m-24 md:m-6 m-2 p-8 lg:p-12 mt-6"> */}


                <div>
                    <h2 className="text-2xl pb-4 font-NotoSansMono font-semibold tracking-normal">Book An Appointment</h2>
                    <p className="md:text-lg text-base text-white font-Ubuntu">
                        Schedule a consultation to explore the best power backup solutions for your business needs.
                    </p>
                </div>
                <div>
                    <h2 className="text-2xl md:pt-4 lg:pt-0 pt-4 pb-4 font-NotoSansMono font-semibold tracking-normal">Pick up in store</h2>
                    <p className="text-lg  text-white font-Ubuntu">
                        Conveniently pick up in store and ensure your business has reliable power backup.
                    </p>
                </div>
                <div>
                    <h2 className="text-2xl md:pt-4 lg:pt-0 pt-4 pb-4 font-NotoSansMono font-semibold tracking-normal">Special packaging</h2>
                    <p className="md:text-lg text-base  text-white font-Ubuntu">
                        Special packaging available to ensure your APC Smart-UPS arrives safely and securely.
                    </p>
                </div>
                
            </div>

      </section>


      <section className="px-2 lg:px-20 pb-0 mt-36 md:mt-0">
        <div className="w-full text-center">
          <h2 className="text-xl lg:text-5xl font-medium text-[#7ab934] uppercase mb-2 md:mb-5">rishik engineering</h2>
          <p className="text-xl md:text-2xl lg:text-2xl text-gray-800 px-1 md:px-0 md:text-center text-justify font-normal text-pretty lowercase">
            <span className='capitalize'>Our</span> dedication to quality, innovation, and customer satisfaction is at the heart of everything we do. Explore our range of high-performance batteries and experience the difference that power and reliability can make in your journey. Welcome to TATA GREEN BATTERY, where your drive begins.
          </p>
        </div>
      </section>

      <section id='services'>
        <ServicesDemo />
      </section>

      <section id='about'>
        <div className='py-14'>
          <div className="bg-[#7ab934]">
            <div className="min-h-[520px] flex justify-center items-center py-10 m-5">
              <div className="container">
                <div className="group grid grid-cols-1 sm:grid-cols-2 gap-6 bg-white rounded-lg px-4 py-4  active:scale-110 duration-200 ">
                  <div className="relative flex justify-center items-center">
                    <img
                      src={aboutbg}
                      alt="About Background"
                      className="max-w-[450px] w-full mx-auto shadow-1 transition-opacity duration-300 group-hover:opacity-0"
                    />
                    <div className="absolute inset-0 flex flex-col items-center justify-center opacity-0 transition-opacity duration-300 group-hover:opacity-100 bg-[#7ab934] text-white m-2 md:m-14 rounded-xl gap-0 md:gap-y-2 ease-in">
                      <p className="text-3xl font-PTSansCaption font-semibold md:text-3xl lg:text-4xl">OUR VALUES</p>
                      <p className="text-lg  font-PTSansCaption font-normal tracking-wide md:text-xl lg:text-2xl">Innovation</p>
                      <p className="text-lg  font-PTSansCaption font-normal tracking-wide md:text-xl lg:text-2xl">Customer Centricity</p>
                      <p className="text-lg  font-PTSansCaption font-normal tracking-wide md:text-xl lg:text-2xl">Integrity</p>
                      <p className="text-lg  font-PTSansCaption font-normal tracking-wide md:text-xl lg:text-2xl">Excellence</p>
                      <p className="text-lg  font-PTSansCaption font-normal tracking-wide md:text-xl lg:text-2xl">Teamwork</p>
                      <p className="text-lg  font-PTSansCaption font-normal tracking-wide md:text-xl lg:text-2xl">Efficiency</p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center gap-6 sm:pt-0 md:px-6">
                    <div>
                      <p className="uppercase text-2xl md:text-5xl font-black text-[#1e1e1e] flex justify-start items-center">
                        About Us
                      </p>
                    </div>
                    <h1 className=" text-4xl text-[#7ab934] font-bold font-cursive">
                      Tata Green Batteries
                    </h1>
                    <p className="text-md tracking-wide font-sans leading-5 text-justify">
                      Since 2020, we've been your trusted partner in power solutions.
                      From the heart of your home to the open road, we illuminate lives
                      with our comprehensive range of Batteries, Inverters, and Solar
                      panels. Experience seamless energy independence with our top-tier
                      Automotive, Two-wheeler, and CCTV & IP Camera Installation. Enjoy the convenience
                      of doorstep delivery as we bring power directly to you. Join us
                      in building a brighter, more sustainable future.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <BestSeller />
      </section>

      <section id='contact'>
        <FormContact />
      </section>
    </div>
  );
};

export default ImageGallery;
