import React, { useState } from 'react';
import Ai from '../assets/images/gallery1.jpg';
import Ai1 from '../assets/images/gallery2.jpg';
import Ai2 from '../assets/images/gallery3.jpg';
import Ai3 from '../assets/images/gallery4.jpg';
import Ai4 from '../assets/images/gallery8.jpg';
import Ai5 from '../assets/images/gallery6.jpg';
import Ai6 from '../assets/images/gallery7.jpg';
// import Ai7 from '../assets/images/gallery8.jpg';

const Cards = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [moreHoveredIndex, setMoreHoveredIndex] = useState(null);

    const handleCardMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleCardMouseLeave = () => {
        setHoveredIndex(null);
        setMoreHoveredIndex(null);
    };

    const handleMoreMouseEnter = (index) => {
        setMoreHoveredIndex(index);
    };

    const handleMoreMouseLeave = () => {
        setMoreHoveredIndex(null);
    };

    return (
        <div>
            <div>
                <h1 className="text-4xl flex justify-center font-bold pt-10">OUR GALLERY</h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-10 md:gap-6 lg:mx-32 py-10">
                {/* First Card */}
                <div
                    className="col-span-2 border shadow-xl rounded-md relative overflow-hidden h-80" // Adjust height as needed
                    onMouseEnter={() => handleCardMouseEnter(0)}
                    onMouseLeave={handleCardMouseLeave}
                >
                    <div className="relative h-full w-full">
                        <img
                            src={Ai}
                            className={`h-full w-full object-cover rounded-lg transition-transform duration-500 origin-bottom-left ${hoveredIndex === 0 ? 'scale-50' : 'scale-100'}`}
                            alt="Gallery Image 1"
                        />
                        <div
                            className={`absolute top-1  p-1 rounded transition-opacity duration-300 ${
                                hoveredIndex === 0 ? 'opacity-100' : 'opacity-0'
                            } flex flex-col items-center justify-start w-full h-full `}
                        >
                            <div className="text-center bg-black bg-opacity-15 w-full py-12">
                                <p>Title</p>
                                <p>Description</p>
                            </div>
                        </div>
                        <div
                            className={`absolute bottom-7 right-7 px-4 py-2 border border-black  rounded-full cursor-pointer z-40 transition-opacity duration-300 ${
                                hoveredIndex === 0 ? 'opacity-100' : 'opacity-0'
                            }`}
                            onMouseEnter={() => handleMoreMouseEnter(0)}
                            onMouseLeave={handleMoreMouseLeave}
                        >
                            More
                        </div>
                    </div>
                    {/* Overlay for First Card */}
                    <div
                        className={`absolute top-0 left-0 h-full w-full bg-gray-800 text-white transform transition-transform duration-700 ease-in-out ${
                            hoveredIndex === 0 && moreHoveredIndex === 0 ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                        }`}
                        style={{
                            zIndex: 60,
                            transformOrigin: 'bottom right',
                            backgroundImage: `url(${Ai})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >
                        <div className="text-center p-4">
                            <p className="text-sm lg:text-xl font-bold">Our Content</p>
                            <p className="text-sm lg:text-xl">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto doloremque sapiente esse tempora ratione voluptatibus, amet quos fuga doloribus voluptates nihil, obcaecati quasi quidem magni, fugiat dolore? Itaque, nesciunt quod.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Second Card */}
                <div
                    className="col-span-1 border shadow-xl rounded-md relative overflow-hidden h-80" // Adjust height as needed
                    onMouseEnter={() => handleCardMouseEnter(1)}
                    onMouseLeave={handleCardMouseLeave}
                >
                    <div className="relative h-full w-full">
                        <img
                            src={Ai1}
                            className={`h-full w-full object-cover rounded-lg transition-transform duration-500 origin-bottom-left ${hoveredIndex === 1 ? 'scale-50' : 'scale-100'}`}
                            alt="Gallery Image 2"
                        />
                        <div
                            className={`absolute top-1 p-1 rounded transition-opacity duration-300 ${
                                hoveredIndex === 1 ? 'opacity-100' : 'opacity-0'
                            } flex flex-col items-center justify-start w-full h-full `}
                        >
                            <div className="text-center bg-black bg-opacity-15 w-full py-12">
                                <p>Title</p>
                                <p>Description</p>
                            </div>
                        </div>
                        <div
                            className={`absolute bottom-7 right-7 px-4 py-2 border border-black  rounded-full cursor-pointer transition-opacity duration-300 ${
                                hoveredIndex === 1 ? 'opacity-100' : 'opacity-0'
                            }`}
                            onMouseEnter={() => handleMoreMouseEnter(1)}
                            onMouseLeave={handleMoreMouseLeave}
                        >
                            More
                        </div>
                    </div>
                    {/* Overlay for Second Card */}
                    <div
                        className={`absolute top-0 left-0 h-full w-full bg-gray-800 text-white transform transition-transform duration-700 ease-in-out ${
                            hoveredIndex === 1 && moreHoveredIndex === 1 ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                        }`}
                        style={{
                            zIndex: 60,
                            transformOrigin: 'bottom right',
                            backgroundImage: `url(${Ai1})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >
                        <div className="text-center p-4">
                            <p className="text-sm lg:text-xl font-bold">Our Content</p>
                            <p className="text-sm lg:text-xl">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto doloremque sapiente esse tempora ratione voluptatibus, amet quos fuga doloribus voluptates nihil, obcaecati quasi quidem magni, fugiat dolore? Itaque, nesciunt quod.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Third Card */}
                <div
                    className="col-span-1 border shadow-xl rounded-md relative overflow-hidden h-80" // Adjust height as needed
                    onMouseEnter={() => handleCardMouseEnter(2)}
                    onMouseLeave={handleCardMouseLeave}
                >
                    <div className="relative h-full w-full">
                        <img
                            src={Ai2}
                            className={`h-full w-full object-cover rounded-lg transition-transform duration-500 origin-bottom-left ${hoveredIndex === 2 ? 'scale-50' : 'scale-100'}`}
                            alt="Gallery Image 3"
                        />
                        {/* <div
                            className={`absolute top-1 left-1 bg-white p-1 rounded transition-opacity duration-300 ${
                                hoveredIndex === 2 ? 'opacity-100' : 'opacity-0'
                            }`}
                        >
                            Title
                        </div> */}
                        <div
                            className={`absolute top-1  p-1 rounded transition-opacity duration-300 ${
                                hoveredIndex === 2 ? 'opacity-100' : 'opacity-0'
                            } flex flex-col items-center justify-start w-full h-full `}
                        >
                            <div className="text-center bg-black bg-opacity-15 w-full py-12">
                                <p>Title</p>
                                <p>Description</p>
                            </div>
                        </div>
                        <div
                            className={`absolute bottom-7 right-7 px-4 py-2 border border-black  rounded-full cursor-pointer transition-opacity duration-300 ${
                                hoveredIndex === 2 ? 'opacity-100' : 'opacity-0'
                            }`}
                            onMouseEnter={() => handleMoreMouseEnter(2)}
                            onMouseLeave={handleMoreMouseLeave}
                        >
                            More
                        </div>
                    </div>
                    {/* Overlay for Third Card */}
                    <div
                        className={`absolute top-0 left-0 h-full w-full bg-gray-800 text-white transform transition-transform duration-700 ease-in-out ${
                            hoveredIndex === 2 && moreHoveredIndex === 2 ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                        }`}
                        style={{
                            zIndex: 60,
                            transformOrigin: 'bottom right',
                            backgroundImage: `url(${Ai2})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >
                        <div className="text-center p-4">
                            <p className="text-sm lg:text-xl font-bold">Our Content</p>
                            <p className="text-sm lg:text-xl">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto doloremque sapiente esse tempora ratione voluptatibus, amet quos fuga doloribus voluptates nihil, obcaecati quasi quidem magni, fugiat dolore? Itaque, nesciunt quod.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Fourth Card */}
                <div
                    className="col-span-1 border shadow-xl rounded-md relative overflow-hidden h-80" // Adjust height as needed
                    onMouseEnter={() => handleCardMouseEnter(3)}
                    onMouseLeave={handleCardMouseLeave}
                >
                    <div className="relative h-full w-full">
                        <img
                            src={Ai3}
                            className={`h-full w-full object-cover rounded-lg transition-transform duration-500 origin-bottom-left ${hoveredIndex === 3 ? 'scale-50' : 'scale-100'}`}
                            alt="Gallery Image 4"
                        />
                        {/* <div
                            className={`absolute top-1 left-1 bg-white p-1 rounded transition-opacity duration-300 ${
                                hoveredIndex === 3 ? 'opacity-100' : 'opacity-0'
                            }`}
                        >
                            Title
                        </div> */}
                        <div
                            className={`absolute top-1  p-1 rounded transition-opacity duration-300 ${
                                hoveredIndex === 3 ? 'opacity-100' : 'opacity-0'
                            } flex flex-col items-center justify-start w-full h-full `}
                        >
                            <div className="text-center bg-black bg-opacity-15 w-full py-12">
                                <p>Title</p>
                                <p>Description</p>
                            </div>
                        </div>
                        <div
                            className={`absolute bottom-7 right-7 px-4 py-2 border border-black  rounded-full cursor-pointer transition-opacity duration-300 ${
                                hoveredIndex === 3 ? 'opacity-100' : 'opacity-0'
                            }`}
                            onMouseEnter={() => handleMoreMouseEnter(3)}
                            onMouseLeave={handleMoreMouseLeave}
                        >
                            More
                        </div>
                    </div>
                    {/* Overlay for Fourth Card */}
                    <div
                        className={`absolute top-0 left-0 h-full w-full bg-gray-800 text-white transform transition-transform duration-700 ease-in-out ${
                            hoveredIndex === 3 && moreHoveredIndex === 3 ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                        }`}
                        style={{
                            zIndex: 60,
                            transformOrigin: 'bottom right',
                            backgroundImage: `url(${Ai3})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >
                        <div className="text-center p-4">
                            <p className="text-sm lg:text-xl font-bold">Our Content</p>
                            <p className="text-sm lg:text-xl">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto doloremque sapiente esse tempora ratione voluptatibus, amet quos fuga doloribus voluptates nihil, obcaecati quasi quidem magni, fugiat dolore? Itaque, nesciunt quod.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Fifth Card */}
                <div
                    className="col-span-1 border shadow-xl rounded-md relative overflow-hidden h-80" // Adjust height as needed
                    onMouseEnter={() => handleCardMouseEnter(4)}
                    onMouseLeave={handleCardMouseLeave}
                >
                    <div className="relative h-full w-full">
                        <img
                            src={Ai4}
                            className={`h-full w-full object-cover rounded-lg transition-transform duration-500 origin-bottom-left ${hoveredIndex === 4 ? 'scale-50' : 'scale-100'}`}
                            alt="Gallery Image 5"
                        />
                        {/* <div
                            className={`absolute top-1 left-1 bg-white p-1 rounded transition-opacity duration-300 ${
                                hoveredIndex === 4 ? 'opacity-100' : 'opacity-0'
                            }`}
                        >
                            Title
                        </div> */}
                        <div
                            className={`absolute top-1 p-1 rounded transition-opacity duration-300 ${
                                hoveredIndex === 4 ? 'opacity-100' : 'opacity-0'
                            } flex flex-col items-center justify-start w-full h-full `}
                        >
                            <div className="text-center bg-black bg-opacity-15 w-full py-12">
                                <p>Title</p>
                                <p>Description</p>
                            </div>
                        </div>
                        <div
                            className={`absolute bottom-7 right-7 px-4 py-2 border border-black  rounded-full cursor-pointer transition-opacity duration-300 ${
                                hoveredIndex === 4 ? 'opacity-100' : 'opacity-0'
                            }`}
                            onMouseEnter={() => handleMoreMouseEnter(4)}
                            onMouseLeave={handleMoreMouseLeave}
                        >
                            More
                        </div>
                    </div>
                    {/* Overlay for Fifth Card */}
                    <div
                        className={`absolute top-0 left-0 h-full w-full bg-gray-800 text-white transform transition-transform duration-700 ease-in-out ${
                            hoveredIndex === 4 && moreHoveredIndex === 4 ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                        }`}
                        style={{
                            zIndex: 60,
                            transformOrigin: 'bottom right',
                            backgroundImage: `url(${Ai4})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >
                        <div className="text-center p-4">
                            <p className="text-sm lg:text-xl font-bold">Our Content</p>
                            <p className="text-sm lg:text-xl">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto doloremque sapiente esse tempora ratione voluptatibus, amet quos fuga doloribus voluptates nihil, obcaecati quasi quidem magni, fugiat dolore? Itaque, nesciunt quod.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Sixth Card */}
                <div
                    className="col-span-1 border shadow-xl rounded-md relative overflow-hidden h-80" // Adjust height as needed
                    onMouseEnter={() => handleCardMouseEnter(5)}
                    onMouseLeave={handleCardMouseLeave}
                >
                    <div className="relative h-full w-full">
                        <img
                            src={Ai5}
                            className={`h-full w-full object-cover rounded-lg transition-transform duration-500 origin-bottom-left ${hoveredIndex === 5 ? 'scale-50' : 'scale-100'}`}
                            alt="Gallery Image 6"
                        />
                        {/* <div
                            className={`absolute top-1 left-1 bg-white p-1 rounded transition-opacity duration-300 ${
                                hoveredIndex === 5 ? 'opacity-100' : 'opacity-0'
                            }`}
                        >
                            Title
                        </div> */}
                        <div
                            className={`absolute top-1  p-1 rounded transition-opacity duration-300 ${
                                hoveredIndex === 5 ? 'opacity-100' : 'opacity-0'
                            } flex flex-col items-center justify-start w-full h-full `}
                        >
                            <div className="text-center bg-black bg-opacity-15 w-full py-12">
                                <p>Title</p>
                                <p>Description</p>
                            </div>
                        </div>
                        <div
                            className={`absolute bottom-7 right-7 px-4 py-2 border border-black  rounded-full cursor-pointer transition-opacity duration-300 ${
                                hoveredIndex === 5 ? 'opacity-100' : 'opacity-0'
                            }`}
                            onMouseEnter={() => handleMoreMouseEnter(5)}
                            onMouseLeave={handleMoreMouseLeave}
                        >
                            More
                        </div>
                    </div>
                    {/* Overlay for Sixth Card */}
                    <div
                        className={`absolute top-0 left-0 h-full w-full bg-gray-800 text-white transform transition-transform duration-700 ease-in-out ${
                            hoveredIndex === 5 && moreHoveredIndex === 5 ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                        }`}
                        style={{
                            zIndex: 60,
                            transformOrigin: 'bottom right',
                            backgroundImage: `url(${Ai5})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >
                        <div className="text-center p-4">
                            <p className="text-sm lg:text-xl font-bold">Our Content</p>
                            <p className="text-sm lg:text-xl">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto doloremque sapiente esse tempora ratione voluptatibus, amet quos fuga doloribus voluptates nihil, obcaecati quasi quidem magni, fugiat dolore? Itaque, nesciunt quod.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Seventh Card */}
                <div
                    className="col-span-1 border shadow-xl rounded-md relative overflow-hidden h-80" // Adjust height as needed
                    onMouseEnter={() => handleCardMouseEnter(6)}
                    onMouseLeave={handleCardMouseLeave}
                >
                    <div className="relative h-full w-full">
                        <img
                            src={Ai6}
                            className={`h-full w-full object-cover rounded-lg transition-transform duration-500 origin-bottom-left ${hoveredIndex === 6 ? 'scale-50' : 'scale-100'}`}
                            alt="Gallery Image 7"
                        />
                        {/* <div
                            className={`absolute top-1 left-1 bg-white p-1 rounded transition-opacity duration-300 ${
                                hoveredIndex === 6 ? 'opacity-100' : 'opacity-0'
                            }`}
                        >
                            Title
                        </div> */}
                        <div
                            className={`absolute top-1 p-1 rounded transition-opacity duration-300 ${
                                hoveredIndex === 6 ? 'opacity-100' : 'opacity-0'
                            } flex flex-col items-center justify-start w-full h-full `}
                        >
                            <div className="text-center bg-black bg-opacity-15 w-full py-12">
                                <p>Title</p>
                                <p>Description</p>
                            </div>
                        </div>
                        <div
                            className={`absolute bottom-7 right-7 px-4 py-2 border border-black  rounded-full cursor-pointer transition-opacity duration-300 ${
                                hoveredIndex === 6 ? 'opacity-100' : 'opacity-0'
                            }`}
                            onMouseEnter={() => handleMoreMouseEnter(6)}
                            onMouseLeave={handleMoreMouseLeave}
                        >
                            More
                        </div>
                    </div>
                    {/* Overlay for Seventh Card */}
                    <div
                        className={`absolute top-0 left-0 h-full w-full bg-gray-800 text-white transform transition-transform duration-700 ease-in-out ${
                            hoveredIndex === 6 && moreHoveredIndex === 6 ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                        }`}
                        style={{
                            zIndex: 60,
                            transformOrigin: 'bottom right',
                            backgroundImage: `url(${Ai6})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >
                        <div className="text-center p-4">
                            <p className="text-sm lg:text-xl font-bold">Our Content</p>
                            <p className="text-sm lg:text-xl">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto doloremque sapiente esse tempora ratione voluptatibus, amet quos fuga doloribus voluptates nihil, obcaecati quasi quidem magni, fugiat dolore? Itaque, nesciunt quod.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cards;
