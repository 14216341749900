import React from "react";
import { capacityIcon, voltageIcon, warrantyIcon } from "../assets/images/svg/index";
import BgModalImage from '../assets/images/blue-green-wave.jpg'

const ProductModal = ({ isOpen, onClose, product }) => {
  if (!isOpen || !product) return null;

  const calculateDiscountedPrice = (price, discountPercentage) => {
    return Math.round(price - price * (discountPercentage / 100));
  };
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
      onClick={onClose}
    >
      <div className="w-11/12 min-h-[60%] mx-2 sm:mx-4 sm:w-5/6 lg:mx-8 bg-white rounded-lg overflow-y-auto no-scroll h-[71%]">
        <div
          className="relative grid grid-cols-1 md:grid-cols-2 p-4 sm:p-6 gap-4"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-2 right-2 sm:top-4 sm:right-4 text-black text-2xl sm:text-3xl"
          >
            &times;
          </button>

          {/* Image Section with Background Image */}
          <div
            className="relative flex justify-center items-center shadow-inner p-4 sm:p-6"
            style={{ backgroundImage: `url(${BgModalImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <img
              src={product.image}
              alt={product.title}
              className="object-contain w-[70%] sm:w-[60%] md:max-h-[80%] transition-transform scale-90 hover:scale-100"
              style={{ zIndex: 1 }}
            />
          </div>

          {/* Product Details Section */}
          <div className="pt-3 px-4 sm:px-6 flex flex-col space-y-3">
            <h2 className="text-lg sm:text-xl md:text-2xl font-bold">
              {product.title}
            </h2>
            <p className="text-xs sm:text-sm md:text-md">
              <span className="font-bold text-pretty tracking-wider">Description: </span>
              {product.description}
            </p>
            <div className="border-b-2 border-dotted border-[#76b9ca]"></div>

            <div className="space-y-2">
              <p className="text-xs sm:text-sm md:text-md font-bold flex gap-x-1.5 items-center">
                <img src={warrantyIcon} alt="Warranty Icon" className="w-4 h-4 sm:w-5 sm:h-5" />
                <span className="pt-1 font-normal">
                  <span className="font-bold text-sm tracking-wider">Warranty: </span>
                  {product.Warranty || "N/A"}
                </span>
              </p>

              <div className="flex flex-col lg:flex-row gap-2 sm:gap-10">
                <p className="text-xs sm:text-sm md:text-md font-bold flex items-center">
                  <img src={capacityIcon} alt="Capacity Icon" className="w-4 h-4 sm:w-5 sm:h-5" />
                  <span className="pt-1.5 font-normal pl-1 text-nowrap">
                    <span className="font-bold tracking-wider ps-1">Capacity: </span>
                    {product.Capacity || "N/A"}
                  </span>
                </p>
                <p className="text-xs sm:text-sm md:text-md font-bold flex items-center gap-x-1.5 pl-0.5 md:pl-0">
                  <img src={voltageIcon} alt="Voltage Icon" className="w-4 h-4 sm:w-5 sm:h-5" />
                  <span className="pt-1.5 font-normal">
                    <span className="font-bold tracking-wider">Battery Voltage: </span>
                    {product.BatteryVoltage || "N/A"}
                  </span>
                </p>
              </div>

              <div className="flex py-2">
                {/* <p className="text-sm sm:text-md font-medium flex">
                  <span className="font-bold tracking-wider">Price: </span>
                  {product.price}
                </p> */}
                <p className="text-xl font-bold text-regal-blue font-mono  mt-2">
                  &#8377;
                  <del className="px-1.5 font-light text-sm text-red-600">{product.price}</del>
                  <span className="">
                    {/* {calculateDiscountedPrice(
                      product.price,
                      discountPercentage
                    )}/- */}
                    {calculateDiscountedPrice(product.price, product.offerpercentage)}/-
                  </span>
                  <span className="bg-[#7AB934] text-white text-base font-semibold px-2 md:ml-3 py-1 rounded">
                    {product.offerpercentage}% off
                  </span>
                </p>
              </div>

              <div className="border-b-2 border-dotted border-[#76b9ca]"></div>
              <div className="flex flex-col sm:flex-row sm:gap-6 pt-4">
                <div>
                  <p className="text-xs sm:text-sm md:text-md font-bold">
                    <span className="font-bold tracking-wider">Do's: </span>
                    <span className="font-medium"> Clean the battery with a moist cloth. When it comes to topping it up, use battery-grade distilled water only. Next, tighten your clamps and apply petroleum jelly.</span>
                  </p>
                </div>
                <div>
                  <p className="text-xs sm:text-sm md:text-md font-bold">
                    <span className="font-bold tracking-wider">Don't: </span>
                    <span className="font-medium">Avoid storing the battery sideways and always use the recommended capacity or interfere with the current charging setting in order to .</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
