import product1 from '../assets/images/product1.png';
import product2 from '../assets/images/product2.png';
import product3 from '../assets/images/product3.png';
import product4 from '../assets/images/product4.png';
import img1 from '../assets/images/carousalimg1.jpg';
import img2 from '../assets/images/carousalimg2.jpg';
import img3 from '../assets/images/carousalimg3.jpg';
import image1 from '../assets/images/TG2.5D.png';
import image2 from '../assets/images/YTZ4.png';
import image3 from '../assets/images/YTZ5.png';
import image4 from '../assets/images/YTZ6.png';
import image5 from '../assets/images/YT5A.png';
import image6 from '../assets/images/YTZ7.png';
import image7 from '../assets/images/TG7D.png';
import image8 from '../assets/images/TG9D.png';
import image9 from '../assets/images/YTZ9.png';
import image10 from '../assets/images/YT14S.png';
import image11 from '../assets/images/34B19L-M (MSIL CO BRANDED).png';
import image12 from '../assets/images/40B20-L&R2436.png';
import image13 from '../assets/images/TG550-L&R.png';
import image14 from '../assets/images/TG700-L&R.png';
import image15 from '../assets/images/70D26-L&R.png';
import image16 from '../assets/images/70D23LBH.png';
import image17 from '../assets/images/40B20-L&R BH.png';
import image18 from '../assets/images/75JEEVAN.png';
import image19 from '../assets/images/95JEEVAN.png';
import image20 from '../assets/images/SLV DIN50L(L1).png';
import image21 from '../assets/images/SLV DIN60-L&R.png';
import image22 from '../assets/images/SLV DIN65L.png';
import image23 from '../assets/images/105E41 ROADSTAR.png';
import image24 from '../assets/images/130F51 ROADSTAR.png';
import image25 from '../assets/images/150G51 ROADSTAR.png';
import image26 from '../assets/images/180H52 ROADSTAR.png';
import image27 from '../assets/images/INTT1800.png';
import image28 from '../assets/images/INTT2200.png';
import image29 from '../assets/images/INTT2400.png';
import image30 from '../assets/images/INTT2600.png';
import image31 from '../assets/images/INTT2800.png';
import image32 from '../assets/images/SWITCHON UPS 1050.png';
import image33 from '../assets/images/SWITCHON UPS 1250.png';


import image34 from '../assets/images/SWITCHON UPS 1250.png';

export const datas = [
    { id: 1, image: image1, productName: 'TG2.5D', Amps: '2.5AH', category: 'Two Wheelers', price: '1211', offer: '20', ratings: '5', description: 'To brave the toughest of weather conditions, we bring you a 100% genuine 65ah SUV battery designed especially for hatchbacks and sedans. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA’s imp', batteryVoltage: '12 Volts', capacity: '2.5AH', warranty: '24 Months Flat + 30 Months Pro-rata'},

    { id: 2, image: image11, productName: '34B19L-M (MSIL CO BRANDED)', Amps: '32AH', category: 'Automobile', price: '4617', offer: '15', ratings: '5', description: 'With the promise of supreme power and durability, we bring you a brand new 65ah SUV battery that is best suited for a utility vehicle. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA’s impeccable industry know-how.', batteryVoltage: '12 volts', capacity: '32AH', warranty: '24 Months Flat + 36 Months Pro-rata'},

    { id: 3, image: image12, productName: '40B20-L/R2436', Amps: '35AH', category: 'Automobile', price: '4698', offer: '15', ratings: '5', description: 'With the promise of supreme power and durability, we bring you a brand new 65ah SUV battery that is best suited for a utility vehicle. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATAs impeccable industry know-how.', batteryVoltage: '12 volts', capacity: '35AH', warranty: '24 Months Flat + 36 Months Pro-rata' },

    { id: 4, image: image27, productName: 'INTT1800', Amps: '150AH', category: 'inverter', price: '18862', offer: '25', ratings: '5', description: 'Our Inverter Batteries are designed with cutting-edge technology and top-notch materials, ensuring they deliver unmatchable performance and have a longer lifespan.', batteryVoltage: '12 volts', capacity: '150AH', warranty: '36 Months Flat + 24 Months Pro-rata' },
];

export const slides = [
  {
    image: img3,
    heading: 'SOLAR PANELS',
    text: 'Efficient & eco-friendly energy',

    
  },
  {
    image: img2,
    heading: 'INVERTER BATTERIES',
    text: 'Reliable power solutions',
  },
  {
    
    image: img1,
    heading: 'TWO WHEELER BATTERIES',
    text: 'High-quality batteries for your needs',
  },
  // Add more slides as needed
];




export const data = [
    {
      id: 1,
      title: 'Invertor Batteries',
      breadcrumb: 'Home > Invertor Batteries',
      product_description: 'Invertor batteries are known for their high energy density and low cost. These batteries are designed to provide a steady and reliable source of power, even in the event of a power outage or other disruption in the electrical grid. They can withstand deep discharge cycles, meaning they can be fully discharged and recharged repeatedly without suffering damage or losing capacity. This makes them ideal for use in off-grid or backup power systems.',
    },
  ];


  export const productData = [
    {
      id: 1,
      category: "TWO WHEELERS",
      image: image1,
      title: 'TG2.5D',
      subtitle: 'Two Wheeler Battery',
      description: 'To brave the toughest of weather conditions, we bring you a 100% genuine 32ah car battery designed especially for hatchbacks and sedans. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how and is engineered to deliver supreme power.',
      Warranty: '24 Months Flat + 30 Months Pro-rata',
      Capacity: '2.5AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '20',
      price: 1211.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 2,
      category: "TWO WHEELERS",
      image: image2,
      title: 'YTZ4',
      subtitle: 'Two Wheeler Battery',
      description: 'To brave the toughest of weather conditions, we bring you a 100% genuine 32ah car battery designed especially for hatchbacks and sedans. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how and is engineered to deliver supreme power.',
      Warranty: '24 Months Flat + 30 Months Pro-rata',
      Capacity: '3AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '20',
      price: 1382.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 3,
      category: "TWO WHEELERS",
      image: image3,
      title: 'YTZ5',
      subtitle: 'Two Wheeler Battery',
      description: 'To brave the toughest of weather conditions, we bring you a 100% genuine 32ah car battery designed especially for hatchbacks and sedans. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how and is engineered to deliver supreme power.',
      Warranty: '24 Months Flat + 30 Months Pro-rata',
      Capacity: '4AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '20',
      price: 1610.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 4,
      category: "TWO WHEELERS",
      image: image4,
      title: 'YTZ6',
      subtitle: 'Two Wheeler Battery',
      description: 'To brave the toughest of weather conditions, we bring you a 100% genuine 32ah car battery designed especially for hatchbacks and sedans. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how and is engineered to deliver supreme power.',
      Warranty: '24 Months Flat + 30 Months Pro-rata',
      Capacity: '5AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '20',
      price: 1756.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 5,
      category: "TWO WHEELERS",
      image: image5,
      title: 'YT5A',
      subtitle: 'Two Wheeler Battery',
      description: 'To brave the toughest of weather conditions, we bring you a 100% genuine 32ah car battery designed especially for hatchbacks and sedans. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how and is engineered to deliver supreme power.',
      Warranty: '24 Months Flat + 30 Months Pro-rata',
      Capacity: '5AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '20',
      price: 1736.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 6,
      category: "TWO WHEELERS",
      image: image6,
      title: 'YTZ7',
      subtitle: 'Two Wheeler Battery',
      description: 'To brave the toughest of weather conditions, we bring you a 100% genuine 32ah car battery designed especially for hatchbacks and sedans. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how and is engineered to deliver supreme power.',
      Warranty: '24 Months Flat + 30 Months Pro-rata',
      Capacity: '6AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '20',
      price: 2142.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 7,
      category: "TWO WHEELERS",
      image: image7,
      title: 'TG7D',
      subtitle: 'Two Wheeler Battery',
      description: 'To brave the toughest of weather conditions, we bring you a 100% genuine 32ah car battery designed especially for hatchbacks and sedans. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how and is engineered to deliver supreme power.',
      Warranty: '24 Months Flat + 30 Months Pro-rata',
      Capacity: '7AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '20',
      price:  1905.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 8,
      category: "TWO WHEELERS",
      image: image8,
      title: 'TG9D',
      subtitle: 'Two Wheeler Battery',
      description: 'To brave the toughest of weather conditions, we bring you a 100% genuine 32ah car battery designed especially for hatchbacks and sedans. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how and is engineered to deliver supreme power.',
      Warranty: '24 Months Flat + 30 Months Pro-rata',
      Capacity: '9AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '20',
      price:  2558.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 9,
      category: "TWO WHEELERS",
      image: image9,
      title: 'YTZ9',
      subtitle: 'Two Wheeler Battery',
      description: 'To brave the toughest of weather conditions, we bring you a 100% genuine 32ah car battery designed especially for hatchbacks and sedans. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how and is engineered to deliver supreme power.',
      Warranty: '24 Months Flat + 30 Months Pro-rata',
      Capacity: '8AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '20',
      price:  2559.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 10,
      category: "TWO WHEELERS",
      image: image10,
      title: 'YT14S',
      subtitle: 'Two Wheeler Battery',
      description: 'To brave the toughest of weather conditions, we bring you a 70ah SUV battery designed especially for Car/SUV. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA’s impeccable industry know-how and is engineered to deliver supreme power.',
      Warranty: '12 Months Flat + 12 Months Pro-rata',
      Capacity: '12.1AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '20',
      price:  8180.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 11,
      category: "AUTOMOBILES",
      image: image11,
      title: '34B19L-M (MSIL CO BRANDED)',
      subtitle: 'AUTOMOTIVE Battery',
      description: 'With the promise of supreme power and durability, we bring you a brand new 65ah SUV battery that is best suited for a utility vehicle. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA’s impeccable industry know-how.',
      Warranty: '24 Months Flat + 36 Months Pro-rata',
      Capacity: '32AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '15',
      price:  4617.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 12,
      category: "AUTOMOBILES",
      image: image12,
      title: '40B20-L/R2436',
      subtitle: 'AUTOMOTIVE Battery',
      description: 'With the promise of supreme power and durability, we bring you a brand new 65ah SUV battery that is best suited for a utility vehicle. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATAs impeccable industry know-how.',
      Warranty: '24 Months Flat + 36 Months Pro-rata',
      Capacity: '35AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '15',
      price:  4698.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 13,
      category: "AUTOMOBILES",
      image: image13,
      title: 'TG550-L/R',
      subtitle: 'AUTOMOTIVE Battery',
      description: 'Created with cutting-edge Japanese technology from the house of GS Yuasa and TATA’s impeccable industry know-how, this new 50ah car battery is best-suited for hatchbacks and sedans.',
      Warranty: '12 Months Flat + 12 Months Pro-rata',
      Capacity: '55AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '15',
      price:  5438.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 14,
      category: "AUTOMOBILES",
      image: image14,
      title: 'TG700-L/R',
      subtitle: 'AUTOMOTIVE Battery',
      description: 'To brave the toughest of weather conditions, we bring you a 100% genuine 60ah SUV battery designed especially for hatchbacks and sedans. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATAs impeccable industry know-how and is engineered to deliver supreme power.',
      Warranty: '12 Months Flat + 6 Months Pro-rata',
      Capacity: '70AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '15',
      price:  6624.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 15,
      category: "AUTOMOBILES",
      image: image15,
      title: '70D26-L/R',
      subtitle: 'AUTOMOTIVE Battery',
      description: 'To brave the toughest of weather conditions, we bring you a 100% genuine 60ah SUV battery designed especially for hatchbacks and sedans. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATAs impeccable industry know-how and is engineered to deliver supreme power.',
      Warranty: '24 Months Flat + 36 Months Pro-rata',
      Capacity: '70AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '15',
      price:  8025.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 16,
      category: "AUTOMOBILES",
      image: image16,
      title: '70D23LBH',
      subtitle: 'AUTOMOTIVE Battery',
      description: 'To brave the toughest of weather conditions, we bring you a 100% genuine 65ah SUV battery designed especially for hatchbacks and sedans. This battery boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how and is engineered to deliver supreme power.',
      Warranty: '30 Months Flat + 36 Months Pro-rata',
      Capacity: '68AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '15',
      price:  7359.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 17,
      category: "AUTOMOBILES",
      image: image17,
      title: '40B20-L/R BH',
      subtitle: 'AUTOMOTIVE Battery',
      description: 'Power your sedans and hatchbacks with our brand new 32ah car battery that boasts of edge-cutting Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how.',
      Warranty: '30 Months Flat + 36 Months Pro-rata',
      Capacity: '35AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '15',
      price:  5480.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 18,
      category: "AUTOMOBILES",
      image: image18,
      title: '75JEEVAN',
      subtitle: 'AUTOMOTIVE Battery',
      description: 'Power your sedans and hatchbacks with our brand new 32ah car battery that boasts of edge-cutting Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how.',
      Warranty: '24 Months Flat + 24 Months Pro-rata',
      Capacity: '75AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '15',
      price:  8618.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 19,
      category: "AUTOMOBILES",
      image: image19,
      title: '95JEEVAN',
      subtitle: 'AUTOMOTIVE Battery',
      description: 'Power your sedans and hatchbacks with our brand new 32ah car battery that boasts of edge-cutting Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how.',
      Warranty: '24 Months Flat + 24 Months Pro-rata',
      Capacity: '90AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '15',
      price:  9749.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 20,
      category: "AUTOMOBILES",
      image: image20,
      title: 'SLV DIN50L(L1)',
      subtitle: 'AUTOMOTIVE Battery',
      description: 'Power your sedans and hatchbacks with our brand new 32ah car battery that boasts of edge-cutting Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how.',
      Warranty: '18 Months Flat + 18 Months Pro-rata',
      Capacity: '50AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '15',
      price:  6534.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 21,
      category: "AUTOMOBILES",
      image: image21,
      title: 'SLV DIN60-L/R',
      subtitle: 'AUTOMOTIVE Battery',
      description: 'Power your sedans and hatchbacks with our brand new 32ah car battery that boasts of edge-cutting Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how.',
      Warranty: '18 Months Flat + 18 Months Pro-rata',
      Capacity: '60AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '15',
      price:  7656.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 22,
      category: "AUTOMOBILES",
      image: image22,
      title: 'SLV DIN65L',
      subtitle: 'AUTOMOTIVE Battery',
      description: 'Power your sedans and hatchbacks with our brand new 32ah car battery that boasts of edge-cutting Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how.',
      Warranty: '18 Months Flat + 18 Months Pro-rata',
      Capacity: '65Ah',
      BatteryVoltage : '12 Volts',
      offerpercentage : '15',
      price:  8316.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 23,
      category: "COMMERCIAL VEHICLES",
      image: image23,
      title: '105E41 ROADSTAR',
      subtitle: 'COMMERCIAL VEHICLES Battery',
      description: 'Fire up your HCV or off-roading vehicle with our brand new 100ah commercial vehicle battery that boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how.',
      Warranty: '18 Months Flat + 18 Months Pro-rata',
      Capacity: '100AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '20',
      price:  9650.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 24,
      category: "COMMERCIAL VEHICLES",
      image: image24,
      title: '130F51 ROADSTAR',
      subtitle: 'COMMERCIAL VEHICLES Battery',
      description: 'Fire up your HCV or off-roading vehicle with our brand new 100ah commercial vehicle battery that boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how.',
      Warranty: '18 Months Flat + 18 Months Pro-rata',
      Capacity: '130AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '20',
      price:  13810.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 25,
      category: "COMMERCIAL VEHICLES",
      image: image25,
      title: '150G51 ROADSTAR',
      subtitle: 'COMMERCIAL VEHICLES Battery',
      description: 'Fire up your HCV or off-roading vehicle with our brand new 100ah commercial vehicle battery that boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how.',
      Warranty: '18 Months Flat + 18 Months Pro-rata',
      Capacity: '150Ah',
      BatteryVoltage : '12 Volts',
      offerpercentage : '20',
      price:  14900.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 26,
      category: "COMMERCIAL VEHICLES",
      image: image26,
      title: '180H52 ROADSTAR',
      subtitle: 'COMMERCIAL VEHICLES Battery',
      description: 'Fire up your HCV or off-roading vehicle with our brand new 100ah commercial vehicle battery that boasts of cutting-edge Japanese technology from the house of GS Yuasa and TATA impeccable industry know-how.',
      Warranty: '18 Months Flat + 18 Months Pro-rata',
      Capacity: '180AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '20',
      price:  19838.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 27,
      category: "INVERTERS",
      image: image27,
      title: 'INTT1800',
      subtitle: 'INVERTOR Battery',
      description: 'Our Inverter Batteries are designed with cutting-edge technology and top-notch materials, ensuring they deliver unmatchable performance and have a longer lifespan.',
      Warranty: '36 Months Flat + 24 Months Pro-rata',
      Capacity: '150AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '25',
      price:  18862.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 28,
      category: "INVERTERS",
      image: image28,
      title: 'INTT2200',
      subtitle: 'INVERTOR Battery',
      description: 'Our Inverter Batteries are designed with cutting-edge technology and top-notch materials, ensuring they deliver unmatchable performance and have a longer lifespan.',
      Warranty: '36 Months Flat + 24 Months Pro-rata',
      Capacity: '180Ah',
      BatteryVoltage : '12 Volts',
      offerpercentage : '25',
      price:  21007.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 29,
      category: "INVERTERS",
      image: image29,
      title: 'INTT2400',
      subtitle: 'INVERTOR Battery',
      description: 'Our Inverter Batteries are designed with cutting-edge technology and top-notch materials, ensuring they deliver unmatchable performance and have a longer lifespan.',
      Warranty: '36 Months Flat + 24 Months Pro-rata',
      Capacity: '200AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '25',
      price:  22151.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 30,
      category: "INVERTERS",
      image: image30,
      title: 'INTT2600',
      subtitle: 'INVERTOR Battery',
      description: 'Our Inverter Batteries are designed with cutting-edge technology and top-notch materials, ensuring they deliver unmatchable performance and have a longer lifespan.',
      Warranty: '36 Months Flat + 24 Months Pro-rata',
      Capacity: '220AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '25',
      price:  23865.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 31,
      category: "INVERTERS",
      image: image31,
      title: 'INTT2800',
      subtitle: 'INVERTOR Battery',
      description: 'Our Inverter Batteries are designed with cutting-edge technology and top-notch materials, ensuring they deliver unmatchable performance and have a longer lifespan.',
      Warranty: '36 Months Flat + 24 Months Pro-rata',
      Capacity: '240AH',
      BatteryVoltage : '12 Volts',
      offerpercentage : '25',
      price:  25011.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 32,
      category: "INVERTERS",
      image: image32,
      title: 'SWITCHON UPS 1050',
      subtitle: 'INVERTOR Battery',
      description: 'Our Inverter Batteries are designed with cutting-edge technology and top-notch materials, ensuring they deliver unmatchable performance and have a longer lifespan.',
      Warranty: '24 Months Pro-rata',
      Capacity: '850VA',
      BatteryVoltage : '12 Volts',
      offerpercentage : '25',
      price:  8125.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 33,
      category: "INVERTERS",
      image: image33,
      title: 'SWITCHON UPS 1250',
      subtitle: 'INVERTOR Battery',
      description: 'Our Inverter Batteries are designed with cutting-edge technology and top-notch materials, ensuring they deliver unmatchable performance and have a longer lifespan.',
      Warranty: '24 Months Pro-rata',
      Capacity: '1000VA',
      BatteryVoltage : '12 Volts',
      offerpercentage : '25',
      price:  9225.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
    {
      id: 34,
      category: "INVERTERS",
      image: image34,
      title: 'SWITCHON UPS 1700',
      subtitle: 'INVERTOR Battery',
      description: 'Our Inverter Batteries are designed with cutting-edge technology and top-notch materials, ensuring they deliver unmatchable performance and have a longer lifespan.',
      Warranty: '24 Months Pro-rata',
      Capacity: '1450VA',
      BatteryVoltage : '12 Volts',
      offerpercentage : '30',
      price:  12900.00,
      do : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis.',
      dont : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat eligendi libero totam accusamus magnam, provident ratione veritatis quis similique.'
    },
  ];