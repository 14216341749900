import React, { useState, useEffect } from "react";
import { capacityIcon, voltageIcon, warrantyIcon } from "../assets/images/svg/index";
import { datas } from "../constantData/indexData";
import BgModalImage from '../assets/images/blue-green-wave.jpg'

const Modal = ({ isOpen, onClose, productId }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    if (productId) {
      const product = datas.find((item) => item.id === productId);
      setSelectedProduct(product);
    }
  }, [productId]);

  if (!isOpen || !selectedProduct) return null;

  // const discountPercentage = 25;
  // const calculateDiscountedPrice = (price, discountPercentage) => {
  //   return Math.round(price - price * (discountPercentage / 100));
  // };

  const calculateDiscountedPrice = (price, discountPercentage) => {
    return Math.round(price - price * (discountPercentage / 100));
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-[#00000044] bg-opacity-75 z-50"
      onClick={onClose}
    >
      <div className="w-11/12 h-2/3 mx-2 sm:mx-4 sm:w-5/6 lg:mx-8 bg-white rounded-lg overflow-y-auto no-scroll ">
        <div
          className="relative grid grid-cols-1 md:grid-cols-2 p-4 sm:p-6 gap-4"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-2 right-2 sm:top-4 sm:right-4 text-black text-2xl sm:text-3xl"
          >
            &times;
          </button>

          {/* Image Section with Background Image */}
          <div
            className="relative flex justify-center items-center shadow-inner p-4 sm:p-6"
            style={{ backgroundImage: `url(${BgModalImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            {/* Overlaying product image */}
            <img
              src={selectedProduct.image}
              alt={selectedProduct.productName}
              className="object-contain w-[70%] sm:w-[60%] md:max-h-[80%] transition-transform scale-90 hover:scale-100"
              style={{ zIndex: 1 }}
            />
          </div>

          {/* Product Details Section */}
          <div className="pt-3 px-4 sm:px-6 flex flex-col space-y-3">
            <h2 className="text-lg sm:text-xl md:text-2xl font-bold">
              {selectedProduct.productName}
            </h2>
            <p className="text-xs sm:text-sm md:text-md">
              <span className="font-bold text-pretty tracking-wider">Description: </span>
              {selectedProduct.description}
            </p>
            <div className="border-b-2 border-dotted border-[#76b9ca]"></div>

            <div className="space-y-2">
              <p className="text-xs sm:text-sm md:text-md font-bold flex gap-x-1.5 items-center">
                <img src={warrantyIcon} alt="Warranty Icon" className="w-4 h-4 sm:w-5 sm:h-5" />
                <span className="pt-1 font-normal">
                  <span className="font-bold tracking-wider">Warranty: </span>
                  {selectedProduct.warranty || "N/A"}
                </span>
              </p>

              <div className="flex-col lg:flex sm:flex-row gap-2 sm:gap-4">
                <p className="text-xs sm:text-sm md:text-md font-bold flex items-center">
                  <img src={capacityIcon} alt="Capacity Icon" className="w-4 h-4 sm:w-5 sm:h-5" />
                  <span className="pt-1.5 font-normal pl-1 text-nowrap">
                    <span className="font-bold tracking-wider ps-1">Capacity: </span>
                    {selectedProduct.capacity || "N/A"}
                  </span>
                </p>
                <p className="text-xs sm:text-sm md:text-md font-bold flex items-center pl-0.5 md:pl-1">
                  <img src={voltageIcon} alt="Voltage Icon" className="w-4 h-4 sm:w-5 sm:h-5" />
                  <span className="pt-1.5 font-normal">
                    <span className="font-bold tracking-wider pl-1 md:pl-1">Battery Voltage: </span>
                    {selectedProduct.batteryVoltage || "N/A"}
                  </span>
                </p>
              </div>
            </div>


            <div className="flex py-2 ps-2">
                {/* <p className="text-sm sm:text-md font-medium flex">
                  <span className="font-bold tracking-wider">Price: </span>
                  {product.price}
                </p> */}
                <p className="text-xl font-bold text-regal-blue font-mono  mt-2">
                  &#8377;
                  <del className="px-1.5 font-light text-sm text-red-600">{selectedProduct.price}</del>
                  <span className="">
                    {/* {calculateDiscountedPrice(
                      selectedProduct.price,
                      discountPercentage
                    )}/- */}
                    {calculateDiscountedPrice(selectedProduct.price, selectedProduct.offer)}/-
                  </span>
                  <span className="bg-[#7AB934] text-white text-base font-semibold px-2 md:ml-3 py-1 rounded">
                    {selectedProduct.offer}% off
                  </span>


                </p>
              </div>

            
            <div className="border-b-2 border-dotted border-[#76b9ca]"></div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="px-2">
                <h3 className="font-semibold text-base sm:text-lg capitalize opacity-70 underline underline-offset-4">Do's</h3>
                <p className="text-justify text-xs sm:text-sm md:text-md pt-3">
                  Clean the battery with a moist cloth. When it comes to topping it up, use battery-grade distilled water only. Next, tighten your clamps and apply petroleum jelly.
                </p>
              </div>

              <div className="px-2">
                <h3 className="font-semibold text-base sm:text-lg capitalize opacity-70 underline underline-offset-4">Dont's</h3>
                <p className="text-justify text-xs sm:text-sm md:text-md pt-3">
                  Avoid storing the battery sideways and always use the recommended capacity or interfere with the current charging setting in order to .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
