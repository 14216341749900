import React, { useState } from "react";
import { datas } from "../constantData/indexData";
import StarRating from "./StarRating";
import Modal from "./Modal"; // Renaming not required
import { batteryIcon, BatteryTerminalIcon } from "../assets/images/svg/index";

const BestSeller = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);

  const openModal = (productId) => {
    setSelectedProductId(productId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProductId(null);
  };


  const calculateDiscountedPrice = (price, discountPercentage) => {
    return Math.round(price - price * (discountPercentage / 100));
  };

  return (
    <div className="px-2 sm:px-6 md:px-12 lg:px-20 xl:px-24  mb-10">
      <div className="flex justify-center items-center">
        <h2 className="text-4xl font-bold text-gray-800">
          Best Sellers
        </h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6 pt-10">
        {datas &&
          datas.map((product) => (
            <>
              <div
                key={product.id}
                className=" flex flex-col bg-white shadow-2xl"
              >
                <div className="relative justify-center items-center flex">
                  {product.image && (
                    <img
                      src={product.image}
                      alt={product.productName}
                      className="w-28 h-32 scale-75 transition-transform duration-300 ease-in-out transform hover:scale-100 cursor-pointer"
                      onClick={() => openModal(product.id)}
                    />
                  )}
                </div>
                <div className="flex flex-col flex-grow px-3 pb-5 space-y-3">
                  <p className="text-xl font-semibold" title={product.productName}>
                    {product.productName.length > 21
                      ? `${product.productName.substring(0, 21)}...`
                      : product.productName}
                  </p>
                  <div className="mt-2 border-t-2 pt-2">
                    <p className="text-sm capitalize flex gap-2">
                      <img src={batteryIcon} alt="Not Found" />
                      <span className="font-bold opacity-50">Capacity: </span>
                      <span className="font-semibold">{product.Amps}</span>
                    </p>
                    <p className="text-lg tracking-wide capitalize flex gap-2 font-semibold text-[#76b9ca]">
                      <img src={BatteryTerminalIcon} alt="Not Found" />
                      <span className="font-bold opacity-50 text-black text-sm pt-1">
                        Category:{" "}
                      </span>
                      {product.category}
                    </p>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <div className="flex items-center">
                      <p className="text-lg font-bold text-regal-blue">
                        ₹{/* {product.price} */}
                        <del className="px-1.5 font-semibold text-sm text-red-600">
                          {product.price}
                        </del>
                        <span className="text-regal-blue">
                          {/* {calculateDiscountedPrice(
                            product.price,
                            discountPercentage
                          )}/- */}
                          {calculateDiscountedPrice(product.price, product.offer)}/-
                        </span>
                      </p>

                      <span className="bg-[#7AB934] text-white text-xs font-semibold px-2 py-1 rounded ml-4">
                        {product.offer}% off
                      </span>
                    </div>
                   
                  </div>
                </div>
                <Modal
                  isOpen={isModalOpen}
                  onClose={closeModal}
                  productId={selectedProductId}
                  productData={datas}
                />
              </div>
            </>
          ))}
      </div>
    </div>
  );
};

export default BestSeller;
