import React, { useEffect } from "react";
import solarPanel from "../assets/images/solarpanel.jpg";
import solarImg from "../assets/images/solar-1.jpg";
// import bg_grid from "../assets/images/grid-sub-header-bg1.jpg";

const SolarPanel = () => {
  const tableData = [
    {
      id: 1,
      tableHead: "If you have a roof of area 100-200 Sq. Ft.",
      headerContent: "TATA POWER SOLAR SOLUTION 1",
      content2: "1 kVA Grid Tie Solar Inverter (Single Phase)",
      content3: "4 nos Modules of 320Wp each",
      content4: "Cables & Other Accessories",
      content5: "You generate 1,400 units annually",
      color: "#30508c",
      headColor: "#fffc7b",
    },
    {
      id: 2,
      tableHead: "If you have a roof of area 200~300 Sq. Ft.",
      headerContent: "TATA POWER SOLAR SOLUTION 2",
      content2: "2 kVA Grid Tie Solar inverter (Single Phase)",
      content3: "7 nos Modules of 320Wp each",
      content4: "Cables & Other Accessories",
      content5: "You generate 2,800 units annually",
      color: "#112040",
      headColor: "#d6a904",
    },
    {
      id: 3,
      tableHead: "If you have a roof of area 300~500 Sq. Ft.",
      headerContent: "TATA POWER SOLAR SOLUTION 3",
      content2: "3 kVA Grid Tie Solar inverter (Single / Three Phase)",
      content3: "10 nos Modules of 320Wp each",
      content4: "Cables & Other Accessories",
      content5: "You generate 4,200 units annually",
      color: "#30508c",
      headColor: "#fffc7b",
    },
    {
      id: 4,
      tableHead: "If you have a roof of area 500~700 Sq. Ft.",
      headerContent: "TATA POWER SOLAR SOLUTION 4",
      content2: "5 kVA Grid Tie Solar inverter (Single / Three Phase)",
      content3: "16 nos Modules of 320Wp each",
      content4: "Cables & Other Accessories",
      content5: "You generate 7,000 units annually",
      color: "#112040",
      headColor: "#d6a904",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div
        style={{ backgroundImage: `url(${solarPanel})` }}
        className="pt-48 bg-cover bg-center bg-no-repeat"
      >
        <h1 className="uppercase text-3xl text-white font-extrabold p-6 bg-black bg-opacity-30">
          Solar Panel
        </h1>
      </div>
      <div className="md:pt-14 pt-8 md:px-20 px-10">
        <h1 className="text-3xl font-bold capitalize">9+ Solar Panel Uses</h1>
        <p className="pt-3 text-justify">
          The core uses of solar panels include generating electricity from
          solar energy. The photons hitting the solar cell lose the electrons
          from their atoms and with a proper attachment of conductors on the
          positive and negative sides of a cell, the whole can be transformed
          into an electrical circuit.
        </p>
        <p className="pt-5 text-justify">
          Investing in rooftop solutions leads to great savings while protecting
          the environment. Tata Power Solar offers solar rooftop solutions for
          homes. Save and earn from your idle rooftop space.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-3 gap-9 md:pl-20 pl-10 md:py-20 py-10">
        <div className="border-r-2 pt-6 pr-4 space-y-1">
          <p className="text-5xl uppercase">Why</p>
          <p className="text-5xl font-extrabold uppercase text-[#7AB934]">
            Solar Power?
          </p>
        </div>
        <div className="leading-6 mr-5">
          <p className="flex gap-3 text-justify italic">
            <span className="font-black text-[#76b9ca]">-</span> Solar energy is
            a clean, renewable energy source that can replace fossil fuels.
          </p>
          <p className="mt-1 flex gap-3 text-justify italic">
            <span className="font-black text-[#76b9ca]">-</span> Solar reduces
            harmful emissions in the atmosphere.
          </p>
          <p className="mt-1 flex gap-3 text-justify italic">
            <span className="font-black text-[#76b9ca]">-</span> While the
            production of solar panels does release emissions, a solar panel
            system's energy payback period is still very low.
          </p>
          <p className="mt-1 flex gap-3 text-justify italic">
            <span className="font-black text-[#76b9ca]">-</span> Solar energy is
            a clean, renewable energy source that can replace fossil fuels.
          </p>
        </div>
        <div className="pt-4">
          <img
            src={solarImg}
            alt="Solar Panel"
            className="hidden md:block w-52 rounded-full"
          />
        </div>
      </div>
      <div className="flex justify-center items-center py-10 bg-gray-100">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 w-full max-w-6xl px-4">
          {tableData.map((item) => (
            <div
              key={item.id}
              className="shadow-lg rounded-lg overflow-hidden bg-white"
            >
              <h2
                className="p-4 text-white font-semibold capitalize text-center"
                style={{ backgroundColor: item.color }}
              >
                {item.tableHead}
              </h2>
              {/* <div
                className="px-4 py-6 text-center"
                style={{
                  backgroundColor: item.headColor,
                  backgroundImage: `url(${bg_grid})`, // Corrected style for background image
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <span className="relative z-10">{item.headerContent}</span>
              </div> */}
              <div className="p-4 space-y-4">
                <div className="border-b pb-4">{item.content2}</div>
                <div className="border-b pb-4">{item.content3}</div>
                <div className="border-b pb-4">{item.content4}</div>
                <div className="pb-4">{item.content5}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SolarPanel;
