import React from "react";
import tatalogo from "../assets/images/tataLogo.png";
import { FacebookIcon, InstagramIcon } from "../assets/images/svg";

const Footerdemo = () => {
  const socialLinks = [
    {
      id: "facebook",
      icon: <FacebookIcon className="w-7 h-7" />,
      url: "https://www.facebook.com/rishikengineering?mibextid=ZbWKwL",
    },
    {
      id: "instagram",
      icon: <InstagramIcon className="w-7 h-7" />,
      url: "https://www.instagram.com/rishikengg?utm_source=qr&igsh=Yno5bjE5dzFhN2Y2",
    },
  ];
  return (
    <div>
      <div className=" ">
        <div className="grid grid-cols-1">
          <div className="col-span-1 h-60">
            <iframe
              title="Map"
              src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d126382.21049033999!2d77.34215517187968!3d8.15793377672038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3b04f10466bf1a4d%3A0xc1f6814e487c62c0!2sEathamozhi%20Rd%2CMaravankudierruppu%2C%20Nagercoil%2C%20Tamil%20Nadu%20629002!3m2!1d8.1579421!2d77.4245571!5e0!3m2!1sen!2sin!4v1723204459978!5m2!1sen!2sin"
              style={{ border: "0", width: "100%", height: "100%" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="grid md:grid-cols-3 lg:grid-cols-3 px-3 md:px-0  p-2 text-white md:gap-x-6 gap-0 bg-[#7ab934]">
          <div className="col-span-1 flex flex-col items-center justify-center pt-5 pb-5 md:pb-10 lg:pt-4 lg:pb-4">
            <img
              src={tatalogo}
              alt="Tata Logo"
              className="w-32 h-10 lg:w-52 lg:h-16"
            />
            <div className="py-3 text-center">
              <p className="mb-1">Eathamozhy road, Maravankdiruppu,</p>
              <p className="mb-1">Nagercoil - 629002.</p>
            </div>
            <div className="social-links mt-3">
              <ul className="list-none flex gap-3">
                {socialLinks.map((link) => (
                  <li key={link.id}>
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.icon}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-span-1 pt-4 font-semibold hidden md:block">
            <p className="tracking-wider text-justify">
              Our store provides a wide range of batteries for every device and
              vehicle, ensuring you stay charged and ready. From everyday
              household batteries to specialized automotive and industrial
              solutions, we have what you need. Depend on us for quality,
              durability, and exceptional service.
            </p>
          </div>
          <div className="col-span-1 pt-4 font-semibold grid tracking-wide">
            <div className="md:px-3 lg:px-14">
            <p className="text-2xl text-justify mb-3">Contact Us</p>
            <p className="mb-2">S T Srisaravanan DEEE, BE</p>
            <p>Phone Number : 9597722394, 6381445744.</p>
            </div>
            
          </div>
        </div>
      </div>
      <div className="bg-[#7ab934] text-white text-center">
        <div className="">
          <hr />
        </div>
        <a target="_blank" href="https://www.eloiacs.com/" rel="noopener noreferrer" className="text-white">
          <p className="py-3">
            &#169; Copyright 2024 <span className="capitalize font-bold tracking-wider ">Eloiacs softwa Pvt Ltd.</span>  All rights reserved.
          </p>{" "}
        </a>
      </div>
    </div>
  );
};

export default Footerdemo;
