import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import ImageGallery from "./pages/ImageGallery";
import Products from "./pages/Products";
import { slides } from "./constantData/indexData";
import SolarPanel from "./pages/SolarPanel";
import Gallery from './pages/Gallery';
import Footerdemo from "./components/Footerdemo";
import Cctv from "./pages/Cctv";
import Demo from "./pages/Demo";

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow mt-14">
          <Routes>
            <Route path="/" element={<ImageGallery images={slides} />} />
            {/* <Route path="/" element={<Demo />} /> */}
            <Route path="/products" element={<Products />} />
            <Route path="/solarpanel" element={<SolarPanel />} />
            <Route path="/cctv" element={<Cctv />} />
            <Route path="/Gallery" element={<Gallery />} />
          </Routes>
        </main>
        <Footerdemo />
      </div>
    </Router>
  );
}

export default App;
