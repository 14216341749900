import React, { useState, useEffect } from "react";
import caurosel1 from "../assets/images/product_banner.jpg";
import ProductModal from "./ProductModal";
import { productData } from "../constantData/indexData";
import { Link } from "react-router-dom";
import BgModalImage from '../assets/images/blue-green-wave.jpg'
import { batteryIcon, voltageIcon, warrantyIcon } from "../assets/images/svg/index";

const Products = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [setContentCategoryId, setSetContentCategoryId] = useState("");

  const categories = [
    { id: "1", categoryName: "TWO WHEELERS" },
    { id: "2", categoryName: "AUTOMOBILES" },
    { id: "3", categoryName: "COMMERCIAL VEHICLES" },
    { id: "4", categoryName: "INVERTERS" },
  ];

  const data22 = {
    categoryId: "1",
    details: {
      id: 1,
      contentDetails: "HOME",
      product_description: "At TATA GREEN BATTERY - RISHIK ENGINEERING, we are dedicated to keeping your vehicles running smoothly with high-performance, reliable automobile batteries. Whether you're driving a car, truck, or motorcycle, our batteries are engineered to provide the energy you need, mile after mile.",
    },
  };

  const [dataHeader, setDataHeader] = useState([data22]);


  const CategoriesDetails = [
    {
      categoryId: "1",
      details: {
        id: 1,
        contentDetails: "TWO WHEELERS",
        product_description: "Welcome to Tata Battery, India's trusted brand for high-performance  two-wheeler, batteries. Whether you ride a motorcycle or a scooter, we have the perfect battery to power your journeys and daily commutes. Our expansive line of products is tailor-made to supercharge your biking experience, delivering unparalleled performance and lasting prowess. With Tata, you don’t just buy a bike battery; you invest in unprecedented quality and peace of mind."
      },
    },
    {
      categoryId: "2",
      details: {
        id: 1,
        contentDetails: "AUTOMOBILES",
        product_description: "Welcome to Tata Batteries, your trusted destination for car and tractor batteries. Our wide range of high-quality car batteries ensures an uninterrupted and smooth drive. Our name is synonymous with trust, and Tata car batteries epitomize the pinnacle of performance, offering the assurance every traveller seeks."
      },
    },
    {
      categoryId: "3",
      details: {
        id: 1,
        contentDetails: "COMMERCIAL VEHICLES",
        product_description: "Welcome to Tata Batteries, your trusted source for high-performance bus and truck batteries that keep your commercial vehicles running smoothly for miles. We understand the need for uninterrupted operations to sustain and grow in this hyper-competitive business environment. That’s why our batteries for commercial vehicles undergo rigorous testing to perform without glitches in extreme conditions, from the harshest of terrains to extremes of heat and cold. Tata's advanced ultra-low maintenance, long-lasting batteries, make sure that they will go the distance and never let you down, no matter how harsh the conditions are."
      },
    },
    {
      categoryId: "4",
      details: {
        id: 1,
        contentDetails: "INVERTERS",
        product_description: "Invertor batteries are known for their high energy density and low cost. These batteries are designed to provide a steady and reliable source of power, even in the event of a power outage or other disruption in the electrical grid. They can withstand deep discharge cycles, meaning they can be fully discharged and recharged repeatedly without suffering damage or losing capacity. This makes them ideal for use in off-grid or backup power systems."
      },
    },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleCategorySelect = (categoryId, categoryName) => {
    setSelectedCategory(categoryName);
    setSetContentCategoryId(categoryId);
    setIsOpen(false);

    const filterHeaderData = CategoriesDetails.filter(
      (item) => item.categoryId === categoryId
    );

    setDataHeader(filterHeaderData);
    console.log(filterHeaderData, "filterHeaderData");
  };

  const handleImageClick = (product) => {
    setSelectedProduct(product);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setSelectedProduct(null);
  };

  const filteredProducts = selectedCategory
    ? productData.filter((product) => product.category === selectedCategory)
    : productData;

  const calculateDiscountedPrice = (price, discountPercentage) => {
    return Math.round(price - price * (discountPercentage / 100));
  };
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <div className="container-fluid mx-auto">
        <div className="flex-col md:flex-row justify-center items-center">
          {/* Image section */}
          <div className="w-full">
            <img src={caurosel1} alt="Not Found" className="w-full" />
          </div>
          <div className="flex-col py-10">
            <div className="flex flex-col md:flex-row ">
              {dataHeader.length > 0
                ? dataHeader.map((item) => (
                  <React.Fragment key={item.categoryId}>
                    <div className="w-full md:w-1/4  flex items-center justify-center text-center px-3 border-e-4 border-e-[#7ab934]">
                      <div className="flex-col justify-center items-center">
                        <p className="text-xl text-[#7ab934] font-bold tracking-normal">
                          {item.details.contentDetails}
                        </p>
                      </div>
                    </div>
                  
                    <div className="w-full md:w-3/4  flex items-center justify-center text-justify px-8">
                      <div className="flex justify-center items-center w-full font-sans">
                        <p>{item.details.product_description}</p>
                      </div>
                    </div>
                  </React.Fragment>
                ))
                : null}
            </div>
          </div>

          <div className="flex-col  mt-4 py-2">
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/3 lg:w-1/4 flex items-start justify-center text-start p-4">
                <div className="">
                  <p className="text-xl font-semibold font-Ubuntu text-regal-blue">Filters</p>
                  <div className="">
                  <div className="flex  w-full gap-16 mt-2 border">
                    <div className="flex flex-col items-center">
                      <p className="mt-2 font-medium px-0 font-Ubuntu">
                        {selectedCategory ? selectedCategory : "CATEGORIES"}
                      </p>
                    </div>
                    <div className=" flex justify-center items-center border-l-2">
                      <button onClick={toggleMenu} className="px-3 font-bold">
                        {isOpen ? "-" : "+"}
                      </button>
                    </div>
                  </div>

                  <div>
                    <ul
                      className={`${isOpen ? "" : "hidden"
                        } cursor-pointer shadow-md rounded-md`}
                    >
                      {categories.map((category) => (
                        <div>
                        <li
                          className="flex justify-start items-start py-3 px-2 text-sm font-thin tracking-wider"
                          key={category.id}
                          onClick={() =>
                            handleCategorySelect(
                              category.id,
                              category.categoryName
                            )
                          }
                        >
                          
                          {category.categoryName}
                        </li>
                        <div className="px-2">
                          <hr  />
                          </div></div>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-5 py-2 font-medium">
                    <Link to="/solarpanel">
                      <p className="font-PTSansCaption">SOLAR PANELS</p>
                    </Link>
                  </div>
                  <div className="mt-2 py-2 font-medium">
                    <Link to="/cctv">
                      <p className="font-PTSansCaption">CCTV</p>
                    </Link>
                  </div>
                  </div>
                  
                </div>
              </div>
              <div className="w-full md:w-3/4 ">
                <div className="overflow-x-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 h-[48rem] no-scroll px-4 py-4 ">
                  {filteredProducts.length > 0 ? (
                    filteredProducts.map((product) => (
                      <div className="">
                        <div
                          key={product.id}
                          className="bg-white w-full shadow-2xl rounded-lg p-4"
                          onClick={() => handleImageClick(product)}
                        >
                          <div className="overflow-hidden" style={{ backgroundImage: `url(${BgModalImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                            <img
                              src={product.image}
                              alt={product.title}
                              className="w-full h-52 object-contain mb-4 transform transition duration-300 hover:scale-125 cursor-pointer"
                            />
                          </div>
                          <div>
                            <h3 className="text-lg font-semibold text-regal-blue" title={product.title}>
                              {product.title.length > 18 &&
                                window.innerWidth >= 768
                                ? `${product.title.substring(0, 18)}...`
                                : product.title.length > 25
                                  ? `${product.title.substring(0, 25)}...`
                                  : product.title}
                            </h3>
                          </div>
                          {/* <div className="py-3">
                            <p className="text-base font-Ubuntu">{product.subtitle}</p>
                          </div> */}
                          <div className="py-3">
                            <p className="text-sm flex pb-2 font-PTSansCaption"><img src={warrantyIcon} alt="Warranty Icon" className="w-6 h-6 sm:w-5 sm:h-5" /><span className="ms-2">{product.Warranty}</span></p>
                            <p className="text-sm flex pb-2 font-PTSansCaption pl-1.5 md:pl-0">
                            <img src={batteryIcon} alt="Capacity Icon" className="w-4 h-4 sm:w-5 sm:h-5" />
                            <span className="ms-2.5 md:ms-2">Capacity : {product.Capacity}</span>
                              
                            </p>
                            <p className="text-sm flex pb-2 ps-1 font-PTSansCaption pl-1.5 md:pl-0 lg:pl-1">
                            <img src={voltageIcon} alt="Voltage Icon" className="w-4 h-4 sm:w-5 sm:h-5" />
                              <span className="ms-2.5 md:ms-1 pt-1">Battery Voltage : {product.BatteryVoltage}</span>
                            </p>

                            <div className="">
                              
                            </div>

                            <p className="text-xl font-extrabold font-mono text-regal-blue ms-2 mt-2">
                              &#8377;
                              <del className="px-1.5 font-light text-base text-red-600">{product.price}</del>
                              <span className="">
                                {/* {calculateDiscountedPrice(
                                  product.price,
                                  discountPercentage
                                )}/- */}
                                {calculateDiscountedPrice(product.price, product.offerpercentage)}/-
                              </span>

                              <span className="bg-[#7AB934] text-white text-sm font-semibold px-2 ml-2  py-1 rounded">
                              {product.offerpercentage}%
                              </span>

                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-span-full text-center py-8">
                      <p className="text-lg font-semibold">
                        No Products Available
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Render the modal */}
      <ProductModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        product={selectedProduct}
      />
    </div>
  );
};

export default Products;
