import React, { useState, useRef, useEffect } from "react";
import bgBateryImage from "../assets/images/battery9.jpg";

const FormContact = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    message: "",
  });
  const [error, setError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false); // Tracks form validation state

  const businessWhatsAppNumber = "919597722394";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (isFormValid) {
      sendWhatsAppMessage();
    }
  }, [isFormValid]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, phoneNumber, email, message } = formData;

    if (!name || !phoneNumber || !email || !message) {
      setError(true);
      setIsFormValid(false);
    } else {
      setError(false);
      setIsFormValid(true); // Trigger WhatsApp message sending
    }
  };

  const sendWhatsAppMessage = () => {
    const { name, phoneNumber, email, message } = formData;
    // Construct WhatsApp message
    const whatsappMessage = `Hello, I am ${name}. My email is ${email}. My phone number is ${phoneNumber}. \n\nMessage: ${message}`;

    // WhatsApp URL with pre-filled message
    const whatsappUrl = `https://wa.me/${businessWhatsAppNumber}?text=${encodeURIComponent(
      whatsappMessage
    )}`;

    // Open WhatsApp with the pre-filled message
    window.open(whatsappUrl, "_blank");

    // Refresh the page after the WhatsApp window is opened
    window.location.reload(); // Refresh the page to reset the form
  };

  return (
    <div
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3   to-black/5 backdrop-blur-md bg-no-repeat w-full h-full  items-center justify-center p-0 md:p-8 pl-0 md:pl-10"
      style={{
        backgroundImage: `url(${bgBateryImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundBlendMode: "blur(20px)",
      }}
    >
      <div className="col-span-1 md:col-span-2 lg:col-span-1 bg-zinc-50/10">
        <div className="border-0 md:border-2 border-white p-6 rounded-3xl shadow-lg shadow-stone-0 md:shadow-stone-50">
          <h2 className="text-4xl font-bold text-white text-center">
            Contact Us
          </h2>
          <form className="blur-none " onSubmit={handleSubmit}>
            <div className="pt-6 flex flex-col">
              <label
                htmlFor="name"
                className="block text-lg font-medium text-white font-Merienda"
              >
                Name
              </label>
              <input
                id="name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className={`block w-full px-3 py-2 text-white bg-transparent border rounded-md shadow-sm focus:outline-none ${
                  error && !formData.name ? "border-red-500" : "border-white"
                }`}
                placeholder="Your Name"
              />
            </div>
            <div className="pt-6 flex flex-col">
              <label
                htmlFor="phoneNumber"
                className="block text-lg font-medium text-white font-Merienda"
              >
                Phone Number
              </label>
              <input
                id="phoneNumber"
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                className={`block w-full px-3 py-2 text-white bg-transparent border rounded-md shadow-sm focus:outline-none ${
                  error && !formData.phoneNumber
                    ? "border-red-500"
                    : "border-white"
                }`}
                placeholder="Your Phone Number"
                maxLength={10}
              />
            </div>
            <div className="pt-6 flex flex-col">
              <label
                htmlFor="email"
                className="block text-lg font-medium text-white font-Merienda"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className={`block w-full px-3 py-2 text-white bg-transparent border rounded-md shadow-sm focus:outline-none ${
                  error && !formData.email ? "border-red-500" : "border-white"
                }`}
                placeholder="Your Email"
              />
            </div>
            <div className="pt-6 flex flex-col">
              <label
                htmlFor="message"
                className="block text-lg font-medium text-white font-Merienda"
              >
                Message
              </label>
              <textarea
                id="message"
                rows="4"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                className={`block w-full px-3 py-2 text-white bg-transparent border rounded-md shadow-sm focus:outline-none ${
                  error && !formData.message ? "border-red-500" : "border-white"
                }`}
                placeholder="Your Message"
              />
            </div>
            {error && (
              <p className="text-red-500 text-center mt-2">
                All fields are required.
              </p>
            )}
            <button
              type="submit"
              className="mt-6 w-full py-2 px-4 bg-[#7ab934] font-Merienda text-white font-semibold rounded-md shadow-sm hover:bg-[#6b9b2a] focus:outline-none focus:ring-opacity-50"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FormContact;
