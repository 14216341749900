import SolarIcon from "../assets/images/svg/S.svg";
import CctvIcon from "../assets/images/svg/C.svg";
import HeaterIcon from "../assets/images/svg/H.svg";
import InverterIcon from "../assets/images/svg/I.svg";
import solarImg from "../assets/images/service1.jpg";
import cctvImg from "../assets/images/service2.jpg";
import solarheaterImg from "../assets/images/service3.jpg";
import ups from "../assets/images/service4.jpg";

import { AnimatePresence, motion } from "framer-motion";
import { useWindowSize } from "./Services";
import { useState } from "react";

const VerticalAccordion = () => {
  // Set the initial open state to the ID of the Solar Panel (id: 1)
  const [open, setOpen] = useState(1);

  return (
    <section className="p-4 mt-6">
        <div className="flex flex-col lg:flex-row justify-center items-center py-4 md:py-8">
            <p className="text-4xl font-bold">Our Services</p>
        </div>
      <div className="flex flex-col lg:flex-row h-fit lg:h-[450px] w-full max-w-7xl mx-auto shadow overflow-hidden rounded-md">
        {items.map((item) => (
          <Panel
            key={item.id}
            open={open}
            setOpen={setOpen}
            id={item.id}
            Icon={item.Icon}
            title={item.title}
            imgSrc={item.imgSrc}
            description={item.description}
            content = {item.content}
          />
        ))}
      </div>
    </section>
  );
};

const Panel = ({ open, setOpen, id, title, Icon, imgSrc, description }) => {
  const { width } = useWindowSize();
  const isOpen = open === id;

  return (
    <>
      <button
        className="bg-regal-blue  transition-colors p-3 border-r-[1px] border-b-[1px] border-slate-200 flex flex-row-reverse lg:flex-col justify-end items-center relative group"
        onClick={() => setOpen(isOpen ? null : id)} // Toggle open state
      >
        <span
          style={{ writingMode: "vertical-lr" }}
          className={`hidden lg:block text-xl font-bold rotate-180 ${isOpen ? 'text-white' : 'text-white'}`}
        >
          {title}
        </span>
        <span className="block lg:hidden text-xl font-bold  text-white">{title}</span>
        <div className="w-6 lg:w-full aspect-square  text-white grid place-items-center">
          {/* {Icon && <img src={Icon} alt={`${title} icon`} className="w-8 h-8" />} */}
        </div>
        <span className="w-4 h-4 bg-regal-blue  transition-colors border-r-[1px] border-b-[1px] lg:border-b-0 lg:border-t-[1px] border-slate-200 rotate-45 absolute bottom-0 lg:bottom-[50%] right-[50%] lg:right-0 translate-y-[50%] translate-x-[50%] z-20" />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            key={`panel-${id}`}
            variants={width && width > 1023 ? panelVariants : panelVariantsSm}
            initial="closed"
            animate="open"
            exit="closed"
            style={{
              backgroundImage: `url(${imgSrc})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className="w-full h-full overflow-hidden relative bg-black flex items-end"
          >
            <motion.div
              variants={descriptionVariants}
              initial="closed"
              animate="open"
              exit="closed"
              className="px-4 py-2 bg-black/40 backdrop-blur-sm text-white"
            >
              <p className="font-Merienda text-sm md:text-xl">{description}</p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      
    </>
    
  );
};

export default VerticalAccordion;

const panelVariants = {
  open: {
    width: "100%",
    height: "100%",
  },
  closed: {
    width: "0%",
    height: "100%",
  },
};

const panelVariantsSm = {
  open: {
    width: "100%",
    height: "200px",
  },
  closed: {
    width: "100%",
    height: "0px",
  },
};

const descriptionVariants = {
  open: {
    opacity: 1,
    y: "0%",
    transition: {
      delay: 0.125,
    },
  },
  closed: { opacity: 0, y: "100%" },
};

const items = [
  {
    id: 1,
    title: "Solar Panel",
    Icon: SolarIcon,
    imgSrc: solarImg, // Use solarImg for Solar Panel
    description:
      "Harness the power of the sun with our state-of-the-art solar panel solutions. We offer comprehensive services, from custom design and professional installation to ongoing maintenance and support.",
  },
  {
    id: 2,
    title: "CCTV",
    Icon: CctvIcon,
    imgSrc: cctvImg,
    description:
      "Our systems are designed to provide 24/7 monitoring, ensuring that every corner of your property is covered. With high-definition cameras, real-time video streaming, and remote access via mobile and desktop applications.",
  },
  {
    id: 3,
    title: "Solar Heater",
    Icon: HeaterIcon,
    imgSrc: solarheaterImg,
    description:
      "With high-efficiency solar collectors, durable materials, and advanced technology, our solar water heaters provide reliable performance year-round, even in less sunny conditions.",
  },
  {
    id: 4,
    title: "Inverter & UPS",
    Icon: InverterIcon,
    imgSrc: ups,
    description:
      "Ensure uninterrupted power supply with our advanced inverters. Designed to efficiently convert and stabilize energy, our inverters offer reliable performance even during power outages.",
  },
  // {
  //   id: 5,
  //   title: "UPS",
  //   Icon: UPSIcon,
  //   imgSrc:
  //     "https://images.unsplash.com/photo-1543286386-713bdd548da4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80",
  //   description:
  //     "We provide comprehensive UPS solutions that protect your equipment from power surges, outages, and fluctuations, ensuring continuous power delivery.",
  // },
];
