import React, {useEffect} from "react";
import solar1 from "../assets/images/solar1.jpg";
import solar2 from "../assets/images/solar2.jpg";
import solar3 from "../assets/images/solar3.jpg";
import solar4 from "../assets/images/solar5.jpg";
import battery5 from"../assets/images/1704377137-.jpg"
import battery6 from"../assets/images/BATTERY-MYTHS-350x191-1.jpg"
import battery7 from"../assets/images/Checking-battery.jpg"
import battery8 from"../assets/images/image-1024x683.jpg"
import battery9 from"../assets/images/solar-panel-installation-workers-fixing-solar-energy-shut.jpg"
 import battery10 from"../assets/images/repairing-a-solar-panel.jpg"
import battery11 from"../assets/images/istockphoto-1473638845-612x612.jpg"
 import battery12 from"../assets/images/City-Hall-Later-Installation1.jpg"
 import {motion} from "framer-motion";
 import GalleryPage from "./GalleryPage"

 const headingVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 0.6 } },
    exit: { x: 100, opacity: 0, transition: { duration: 0.6 } },
  };
  
  
  
  
  
  const OurProject = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return (
      <>
      <div>




   <GalleryPage />
   <div className="md:flex  flex-row p-6 md:p-20 gap-14">
          <div className="  mt-5">
            <strong><p className="text-5xl justify-center bg-[#7ab934] p-14">01</p></strong>
  
          </div>
          <div className=" ">
            <strong><motion.p
            variants={headingVariants}
            initial="hidden"
            whileInView="visible"
            exit="exit"
             className="text-3xl md:text-5xl ">We Are Increasing Business Success With IT Solution</motion.p></strong>
            <p className="pt-5  text-sm md:text-lg italic">Amet luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor. A lacus vestibulum sed arcu non. Dolor magna eget est lorem ipsum dolor sit amet consectetur. Mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus. Nec feugiat nisl pretium fusce id. Justo laoreet sit amet cursus sit amet. Porta non pulvinar neque laoreet suspendisse interdum consectetur libero.</p>
  
          </div>
         
  
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 p-10 text-lg">
          <div className="">
            <ul className="list-disc list-inside md:pb-0 pb-20 md:pt-20 pl-10 font-Sedan">
           <li className="pb-4">Modern CCTV systems are becoming increasingly easy </li>
           <li className="pb-4">With so many options, CCTV is affordable to most people</li>
           <li className="pb-4">Modern CCTV cameras with motion sensors can notify</li>
           <li className="pb-4">Real-time footage of your home or business can give </li>
           <li className="pb-4">CCTV cameras can deter criminals</li>
           <li className="pb-4">If a crime takes place, CCTV can provide valuable</li>
            </ul>
  
          </div>
          <div className="md:flex flex-row gap-4 ">
            <div>
              <img className="hover:scale-105 duration-5 transition-transform w-[400px] h-[200px]" src={solar4} />
              <img className="pt-4 hover:scale-105 duration-500 transition-transform w-[400px] h-[200px]" src={solar1} />
            </div>
            <div className="pt-4 md:pt-0">
              <img className="hover:scale-105 duration-500 transition-transform w-[400px] h-[200px] " src={solar3} />
              <img className="pt-4 hover:scale-105 duration-500 transition-transform w-[400px] h-[200px]" src={solar2} />
            </div>
          </div>
        </div>
        <div className="md:flex flex-row p-6 md:p-20 gap-14">
          <div className=" mt-5 ">
            <strong><p className="text-5xl flex justify-center bg-[#7ab934] p-14 ">02</p></strong>
  
          </div>
          <div className=" "
  
          >
            <strong><motion.p 
            variants={headingVariants}
            initial="hidden"
            whileInView="visible"
            exit="exit"
  
            className="text-2xl md:text-5xl ">We Are Increasing Business Success With IT Solution</motion.p></strong>
            <p className="pt-5 text-sm md:text-lg italic">Amet luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor. A lacus vestibulum sed arcu non. Dolor magna eget est lorem ipsum dolor sit amet consectetur. Mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus. Nec feugiat nisl pretium fusce id. Justo laoreet sit amet cursus sit amet. Porta non pulvinar neque laoreet suspendisse interdum consectetur libero.</p>
  
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 p-10 gap-24 ">
          <div className="md:flex flex-row gap-4 ">
            <div className="">
              <img className="hover:scale-105 duration-5 transition-transform w-[400px] h-[200px] " src={battery5} />
              <img className="pt-4 hover:scale-105 duration-500 transition-transform w-[400px] h-[200px]" src={battery6} />
            </div>
            <div className="pt-4 md:pt-0">
              <img className="hover:scale-105 duration-500 transition-transform w-[400px] h-[200px]" src={battery7} />
              <img className="pt-4 hover:scale-105 duration-500 transition-transform w-[400px] h-[200px]" src={battery8} />
            </div>
          </div>
          <div>
            <ul className="list-disc list-inside md:pt-14 pt-2 pl-10 font-Sedan text-lg">
    <li className="pb-4">Save money on your household bills</li>
  <li className="pb-4">Reduce your carbon footprint</li>	
  <li className="pb-4">Generate clean, renewable energy</li>
  <li className="pb-4">Protect yourself against rising electricity costs</li>
  <li className="pb-4">Increase the value of your home	</li>
  <li className="pb-4">Take advantage of Australia’s excellent solar conditions</li>
  <li className="pb-4">Experience low-maintenance ownership	</li>
  {/* <li className="pb-4">Enjoy fast payback plus a long system lifespan</li> */}
            </ul>
          </div>
  
        </div>
        <div className="md:flex flex-row p-6 md:p-20 gap-14">
          <div className="mt-5 ">
            <strong><p className="text-5xl flex justify-center pt-14  bg-[#7ab934] p-14">03</p></strong>
  
          </div>
          <div className=" ">
            <strong><motion.p 
            variants={headingVariants}
            initial="hidden"
            whileInView="visible"
            exit="exit"
            className=" text-2xl md:text-5xl">We Are Increasing Business Success With IT Solution</motion.p></strong>
            <p className="pt-5 text-sm md:text-lg italic">Amet luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor. A lacus vestibulum sed arcu non. Dolor magna eget est lorem ipsum dolor sit amet consectetur. Mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus. Nec feugiat nisl pretium fusce id. Justo laoreet sit amet cursus sit amet. Porta non pulvinar neque laoreet suspendisse interdum consectetur libero.</p>
  
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 p-10 ">
          <div>
          <ul className="list-disc list-inside pt-2 pb-20 md:pb-0 md:pt-16 pl-10 font-Sedan text-lg">
           <li className="pb-4">Modern CCTV systems are becoming increasingly easy </li>
           <li className="pb-4">With so many options, CCTV is affordable to most people</li>
           <li className="pb-4">Modern CCTV cameras with motion sensors can notify</li>
           <li className="pb-4">Real-time footage of your home or business can give </li>
           <li className="pb-4">CCTV cameras can deter criminals</li>
           <li className="pb-4">If a crime takes place, CCTV can provide valuable</li>
            </ul>
          </div>
          <div className="md:flex flex-row gap-4 ">
            <div>
              <img className="hover:scale-105 duration-5 transition-transform w-[400px] h-[200px] " src={battery9} />
              <img className="pt-4 hover:scale-105 duration-500 transition-transform w-[400px] h-[200px]" src={battery10} />
            </div>
            <div className="pt-4 md:pt-0">
              <img className="hover:scale-105 duration-500 transition-transform w-[400px] h-[200px]" src={battery11} />
              <img className="pt-4 hover:scale-105 duration-500 transition-transform w-[400px] h-[200px]" src={battery12} />
            </div>
          </div>
        </div>
  
  
        </div>
  
      </>
  
  
    );
  };
  
  export default OurProject;