import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import tatalogo from '../assets/images/tataLogo.png';
import tatalogo1 from '../assets/images/tataLogo_1.png';
import { contacticon } from '../assets/images/svg';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path, hash) => {
    setIsOpen(false); // Close the menu after navigation
  
    if (location.pathname === path && !hash) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate(path); // Navigate to the specified path
  
      if (hash) {
        // Wait for the route to change and the component to re-render
        setTimeout(() => {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100); // Delay the scroll to ensure the page has loaded
      }
    }
  };
  

  return (
    <nav className="bg-[#7ab934] fixed w-full z-40 top-0  px-2 md:px-10">
      <div className="container mx-auto flex justify-between pt-2 pb-2 items-center">
        <div className='px-10'>
          <Link to='/'>
            <img 
              src={tatalogo} 
              alt="Tata Logo" 
              className='hidden md:block w-36 h-10' 
            />
          </Link>
          <Link to='/'>
            <img 
              src={tatalogo1} 
              alt="Tata Logo Small" 
              className='block md:hidden w-14 h-10' 
            />
          </Link>
        </div>
        
        <button
          className="text-white lg:hidden"
          onClick={toggleNavbar}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
        <div className={`hidden lg:flex lg:items-center lg:gap-16`}>
          <ul className="flex gap-16 text-white text-lg font-semibold tracking-wider">
            <li className="nav-item cursor-pointer">
              <Link to="/" onClick={() => handleNavigation('/')}>Home</Link>
            </li>
            <li className="nav-item cursor-pointer" onClick={() => handleNavigation('/', '#services')}>
              Our Services
            </li>
            <li className="nav-item cursor-pointer" onClick={() => handleNavigation('/', '#about')}>
              About Us
            </li>
            <li className="nav-item cursor-pointer">
              <Link to="/products" onClick={() => handleNavigation('/products')}>Products</Link>
            </li>
            <li className="nav-item cursor-pointer">
              <Link to="/Gallery" onClick={() => handleNavigation('/Gallery')}>Gallery</Link>
            </li>
            {/* <li className="nav-item cursor-pointer" onClick={() => handleNavigation('/', '#contact')}>
              Contact
            </li> */}
            <li className="nav-item cursor-pointer" onClick={() => handleNavigation('/', '#contact')}>
              <img src={contacticon} alt="Not Found" className='w-10 h-10'/>
            </li>
          </ul>
        </div>
      </div>
      <div className={`lg:hidden ${isOpen ? 'block' : 'hidden'}`}>
        <ul className="flex flex-col text-white mt-4">
          <li className="nav-item cursor-pointer mb-2">
            <Link to="/" onClick={() => handleNavigation('/')}>Home</Link>
          </li>
          <li className="nav-item cursor-pointer mb-2" onClick={() => handleNavigation('/', '#services')}>
            Our Services
          </li>
          <li className="nav-item cursor-pointer mb-2" onClick={() => handleNavigation('/', '#about')}>
            About Us
          </li>
          <li className="nav-item cursor-pointer mb-2">
            <Link to="/products" onClick={() => handleNavigation('/products')}>Products</Link>
          </li>
          <li className="nav-item cursor-pointer mb-2">
            <Link to="/Gallery" onClick={() => handleNavigation('/Gallery')}>Gallery</Link>
          </li>
          {/* <li className="nav-item cursor-pointer mb-2" onClick={() => handleNavigation('/', '#contact')}>
            Contact
          </li> */}
          <li className="nav-item cursor-pointer mb-2" onClick={() => handleNavigation('/', '#contact')}>
              <img src={contacticon} alt="Not Found" className='w-10 h-10'/>
            </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
