import React, { useEffect } from "react";
import cctvimg from "../assets/images/cctv.jpg";
import cctvIcon from "../assets/images/svg/cctv-svgrepo-com.svg";
import cctvimage from "../assets/images/img1-4.jpg";
import camera from "../assets/images/cctvimg.jpg";
import cameraIcon from "../assets/images/svg/camera-svgrepo-com.svg";
import gallery from "../assets/images/svg/gallery-svgrepo-com.svg";
import tools from "../assets/images/svg/tools-svgrepo-com.svg";

const Cctv = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* Hero Section */}
      <div
        style={{ backgroundImage: `url(${cctvimg})` }}
        className="bg-no-repeat bg-cover bg-center flex items-center"
      >
        <div className="text-center md:text-left text-2xl md:text-4xl text-white uppercase font-bold md:px-28 px-10 pt-36 pb-14">
          <h1 className="pb-2">
            safety <span className="text-[#7AB934]">& </span> security
          </h1>
          <h1 className="md:text-2xl text-lg font-medium">
            at your finger prints
          </h1>
        </div>
      </div>

      {/* Introduction Section */}
      <div className="text-center px-5 md:px-20 pt-10">
        <div className="flex items-center justify-center pt-10">
          <div className="border-t-2 border-gray-400 w-16 mx-4"></div>
          <img src={cctvIcon} alt="Not Found" className="w-12 h-12" />
          <div className="border-t-2 border-gray-400 w-16 mx-4"></div>
        </div>
        <p className="capitalize font-semibold text-lg md:text-xl">
          360 total security services
        </p>
        <p className="capitalize font-bold text-xl md:text-4xl text-[#7ab934] pt-3">
          CCTV secure 360 surveillance systems
        </p>
        <p className="text-center text-sm md:text-base lg:text-lg pt-5">
          Private companies, hospitals, prisons, malls, and other types of
          organizations use CCTV to keep a close eye on every inch of the
          premises. Other uses include: Toll plazas to catch people not paying.
          Intersection cameras to monitor and ensure that drivers stop for red
          lights and stop signs.
        </p>
      </div>

      {/* Content Section */}
      <div className="py-16 md:py-20 px-4 md:px-20 grid gap-8 md:gap-12 xl:gap-16 grid-cols-1 xl:grid-cols-2">
        <div >
          <h1 className="capitalize text-xl md:text-2xl font-medium text-regal-blue md:text-black">
            <span className=" md:border-b-2 md:border-[#016bb2] pb-2">ensure </span>{" "}
            safety in your surroundings
          </h1>
          <p className="capitalize pt-6 text-base md:text-lg">
            where caution blends with technology!
          </p>
          <p className="pt-3 text-sm md:text-base">
            By installing a CCTV camera or cameras in strategic locations in
            your area of operation, you enhance security with the help of a
            security company. You prevent acts of vandalism, serious crimes, and
            break-ins. This is extremely vital in high crime areas.
          </p>
          <img
            src={cctvimage}
            className="pt-6 md:pt-10 w-full object-cover"
            alt="CCTV Surveillance"
          />
        </div>

        {/* Blurred Background Section */}
        <div className="bg-gray-200 p-4 md:p-6 lg:p-8 xl:p-10  relative overflow-hidden">
          <div
            style={{
              backgroundImage: `url(${camera})`,
              filter: "blur(10px)",
            }}
            className="absolute inset-0 bg-no-repeat bg-cover bg-center"
          ></div>
          <div className="relative z-10 text-center md:text-left">
            <p className="capitalize font-semibold text-lg md:text-xl lg:text-2xl pb-6">
              latest device
            </p>
            <p className="capitalize text-2xl md:text-4xl pb-3 font-bold text-[#016bb2]">
              unbelievable results
            </p>

            <div className="flex gap-4 pt-6">
              
              <img
                src={gallery}
                className="border-2 bg-white border-white p-4 my-7  rounded-md "
                alt="Gallery Icon"
              />
              <div>
                <p className="font-semibold text-base md:text-lg uppercase">
                  Ultra-High Definition (4K and 8K) Resolution
                </p>
                <p className="text-sm md:text-base">
                Experience crystal-clear video quality with ultra-high definition cameras that capture even the finest details. These cameras are ideal for both large-scale industrial sites and residential properties, where image clarity is paramount.
                </p>
              </div>
            </div>

            <div className="flex gap-4 pt-6">
              <img
                src={tools}
                className="border-2 bg-white border-white p-4 my-7  rounded-md "
                alt="Tools Icon"
              />
              <div>
                <p className="font-semibold text-base md:text-lg uppercase">
                  AI-Powered Analytics
                </p>
                <p className="text-sm md:text-base">
                Our latest CCTV systems are equipped with artificial intelligence, enabling advanced features like facial recognition, object detection, and behavior analysis. These intelligent cameras can identify suspicious activities, reducing false alarms and enhancing security response times.
                </p>
              </div>
            </div>

            <div className="flex gap-4  pt-6">
              <img
                src={cameraIcon}
                className="border-2 bg-white border-white p-4 my-7  rounded-md"
                alt="Camera Icon"
              />
              <div>
                <p className="font-semibold text-base md:text-lg uppercase">
                  360-Degree Panoramic View
                </p>
                <p className="text-sm md:text-base">
                Say goodbye to blind spots with our 360-degree cameras. These devices offer complete coverage of an area, providing a panoramic view that ensures nothing goes unnoticed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="text-center pb-8">
        <blockquote className="font-semibold text-xl md:text-3xl font-DancingScript">"In a world where security is paramount, our CCTV systems are the guardians you can trust."</blockquote>
      </div>
    </div>
  );
};

export default Cctv;
